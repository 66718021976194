/***********************************************************************************
 * Module:          envConfig
 *
 * Description:     EAP environment config. The appropriate environment config
 *                  is taken at build time depending on the --env envName parameter
 ***********************************************************************************/

/**
 * Use CommonJS module syntax as this module is
 * dynamically loaded based on build env parameter
 */
module.exports = {

  aodocsInstallUrl: 'https://welcome.aodocs.com/dms/',

  clientIds: {
    aodocs: '993707107224-pldsqo168qc45lrn2dmmnk8ahd0vuo5n.apps.googleusercontent.com',
    smartbar: '830930251046-ook64nsq8v7k7ec4qhc9t2udt1sn77gd.apps.googleusercontent.com'
  },

  allowedExtensionIds: {
    sb: 'egmpgdhdonfhjmbiabimfjeahdcdpeap',
    ufo: 'cgabamcgnkgaomfdieapdffpbkicgoog'
  }

};
